import React from 'react'
interface CardInfoProps {
    title?: string
    edit?: boolean
    prop: any
    alt?: string
    state?:any
    setState?:any
}
const CardInfo = ({ title, edit, prop, alt,state,setState }: CardInfoProps) => {
    return (
        <div className=' w-full py-4'>
            <label className=' font-semibold'>{title} </label>
            <div className='px-5 mt-3  py-3 border-gray-300 bg-gray-200 border-[1px] rounded-md w-full'>
                {!edit ?
                    prop ? <p>{prop}</p> : <p className=' text-gray-500'>{alt}</p>
                    :
                    <input
                        type="text"
                        name="item"
                        value={state}
                        onChange={(e)=> setState(e.target.value) }
                        placeholder={alt}
                        className='text-black w-full focus:outline-none bg-inherit'
                    />
                }
            </div>
        </div>
    )
}

export default CardInfo