import { auctionSteps, auctionSteps_en } from '../../constant';
import { useTranslation } from 'react-i18next';

const Step = () => {
    
    const {t,i18n} =useTranslation();
    const steps = i18n.language === "ar" ? auctionSteps : auctionSteps_en;
    return (
        <div className='bg-darkColor overflow-hidden'>
            <div className='max-container main-prop padding-container'>
                <div className='grid md:grid-cols-2 xs:grid-cols-1 text-white'>
                    <div className='flex-1 max-container   '>
                    <h2 className='pb-5 text-xl  text-primary font-bold'>{t("step-title")}</h2>
                        <ul className=' grid gap-10 '>
                            
                            {steps.map((step, index) => (
                                <li key={index} className=' relative' data-aos="fade-up" data-aos-delay={`${index+1}00`}>
                                    <div className={`h-full absolute ${i18n.language==="ar"?"right-0":"left-0"}  top-0`}>
                                        <p className=' text-lg font-bold w-10 h-10 flex-center rounded-full bg-secondry text-white'>{step.step}</p>
                                        <div data-aos="fade-in" data-aos-delay={`${index+2}00`} className={`h-full w-[2px] bg-gray-400 translate-x-1/2 relative ${i18n.language==="ar"?"-left-1/2":"-right-1/2"} ${step.step === 5 && "hidden"}`} ></div>
                                    </div>
                                    <div className=' ps-14'>
                                        <h3 className=' text-lg font-bold'>{step.title}</h3>
                                        <p>{step.description}</p>
                                    </div>

                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className=' w-full h-full flex-1'>
                        <img className='w-full h-full' src={"/asset/steps.webp"} alt='steps'/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Step

