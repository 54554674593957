import SectionHeader from '../ui/SectionHeader';
import { bestAuctions, bestAuctions_en } from '../../constant';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

import AuctionCard from '../ui/AuctionCard';
import { Auction } from '../../types';
const BestAuction = () => {
    const { t, i18n } = useTranslation();
    const globalBestAuctions:Auction[] = i18n.language === "ar" ? bestAuctions : bestAuctions_en;
/* ///////remove this comment to fetch plans from backend//////
    const [globalBestAuctions, setGlobalBestAuctions] = useState< Auction[]>([]);
    useEffect(() => {
        const loadBestAuctions = async () => {
            try {
                const data = await fetchBestAuctions();
                setGlobalBestAuctions(data);
            } catch (error) {
                toast.error('Failed to fetch plans.');
            }
        };
        loadBestAuctions();
    }, []);  */
    return (
        <section className='main-prop ' id='best-auctions'>
            <div className='max-container padding-container overflow-hidden'>
                <SectionHeader title={t("best-auction")} />
                <div>
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={3}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                        }}
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                    >
                        {globalBestAuctions.map((item: Auction, index: number) => (
                            <SwiperSlide key={index}>
                                <AuctionCard item={item} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className='grid pt-4 text-blue-500 text-lg'>
                    <Link to={"#"} className=' justify-self-end underline'>{t("see-all")}</Link>
                </div>
            </div>
        </section>
    )
}

export default BestAuction
