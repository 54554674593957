import { useTranslation } from 'react-i18next'
import Logo from '../ui/Logo'

const SignText = () => {

    const { t, i18n } = useTranslation()
    return (
        <div className=" hero sm:relative xs:absolute sm:flex xs:w-full sm:w-3/5 padding-container h-screen flex-col justify-center text-start">
            <div className='login-overlay'></div>
            <div className=' relative z-20 text-white flex flex-col justify-center h-full'>
                <div className={` absolute ${i18n.language==="ar"?"right-0" :"left-0"} top-5 `}>
                    <Logo />
                </div>
                <div className=' xs:hidden sm:block'>
                    <h1 className=' text-5xl font-bold text-primary' >{t("title")}</h1>
                <p className=' text-xl font-bold py-5'>{t("hero-title")}</p>
                <p className=' text-xl font-bold  '>{t("hero-disc")}</p>
                </div>
                
            </div>
        </div>
    )
}

export default SignText