import LoginForm from '../../components/forms/LoginForm';
import SignText from '../../components/signtext/SignText';
const Login = () => {
    return (
        <div className='  flex-center h-[100vh] overflow-hidden'>
            <SignText />
            <LoginForm />
        </div>
    )
}

export default Login
