import { useEffect, useState } from 'react'
import Logo from '../ui/Logo'
import MainLink from '../ui/MainLink'
import Navbar from './Navbar'
import { FaBars, FaRegUserCircle } from 'react-icons/fa'
import MobileNavbar from './MobileNavbar'
import Aos from 'aos'
import "../../aos.css"
import { useTranslation } from 'react-i18next'
import { useStateContext, GlobalStateContext } from '../../context/StateContext'
import { Link } from 'react-router-dom'
import { fetchUser } from '../../utils/data'
const Header = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const { user, setUser } = useStateContext() as GlobalStateContext;
    useEffect(() => {
        Aos.init({
            easing: 'ease-in-out',
            duration: 1000,
            delay: 200
        });
        const getUserData = async () => {
            
            try {
                const userData = await fetchUser();
                setUser(userData);
            } catch (error) {
                console.error('Error fetching user data', error);
            }
        };
        getUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <header className='  text-white relative z-40' >
            <div className=' max-container padding-container py-6 border-b-[1px] border-gray-500'>
                <div className=' flex-between'>
                    <Logo />
                    <div className='md:flex xs:hidden'>
                        <Navbar />
                    </div>
                    <div className=' flex-center gap-x-4'>
                        {
                            user ?
                                <Link to={`/profile/${user.id}`} className='text-3xl text-primary'>
                                    <FaRegUserCircle />
                                </Link> :
                                <MainLink title={t("login")} href={`/login`} />
                        }
                        <button type='button' onClick={() => setOpen(true)} title='open menu' className=' xs:flex md:hidden border-[1px] p-1 text-xl'>
                            <FaBars />
                        </button>
                    </div>
                </div>
            </div>
            <div>

                <MobileNavbar open={open} setOpen={setOpen} />
            </div>
        </header>
    )
}

export default Header

