import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Logo = () => {
  const {t} =useTranslation();
  return (
    <Link to={"/"} className='text-3xl font-bold text-primary'>
        {t("title")} .
    </Link>
  )
}

export default Logo
