import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { navlinks, navlinks_en } from '../../constant/profiledata';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { CgLogOut } from "react-icons/cg";
import Cookies from 'js-cookie';
import { navbarlinks, navbarlinks_en } from '../../constant';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdOutlineDomainVerification } from 'react-icons/md';
import LanguageSwitcher from '../ui/LanguageSwitcher';
interface SidebarProps {
    user: any;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const Sidebar = ({ user, setOpen }: SidebarProps) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [showMainLink, setShowMainLink] = useState(false);
    const links = i18n.language === "ar" ? navlinks : navlinks_en;
    const mainLinks = i18n.language === "ar" ? navbarlinks : navbarlinks_en;
    return (
        <div className='flex justify-between h-full flex-col'>
            <div>
                <div className=' w-full flex-center bg-black gap-5 px-6 py-5'>
                    <div>
                        <div className='w-12 h-12 '>
                            <img src="/asset/person.jpg" className=' w-full h-full rounded-full' alt="user" />
                        </div>
                    </div>
                    <div>
                        <p className='pb-1'>{t("hello-again")}</p>
                        <p className=' text-primary font-bold'>{user?.name}</p>
                    </div>
                </div>
                <div className=' flex flex-col gap-t-4'>
                    <ul className=' pt-8'>
                        {links.map((link, index) => (
                            <li key={index} >
                                <NavLink to={`/profile/${user?.id}/${link.link}`} onClick={() => setOpen(false)} className={({ isActive }) =>
                                    `flex items-center py-3 px-6 gap-3 w-full text-weakColor ${isActive ? 'text-white px-2 border-s-8 border-primary' : ''}`}>
                                    <p className=' text-primary text-xl'>{link.icon}</p>
                                    <p>{link.title}</p>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <button onClick={()=>setShowMainLink(!showMainLink)} className='flex items-center py-3 px-6 gap-3 w-full text-weakColor'>
                        <p className=' text-primary text-xl'><MdOutlineDomainVerification /></p>
                        <div className={`${showMainLink?"text-white":""} flex gap-2 items-center`}>
                            <p>{t("important-links")}</p>
                            <p className=' text-lg'>{showMainLink?<IoIosArrowUp />:<IoIosArrowDown />}</p>
                        </div>
                    </button>
                    { showMainLink&&
                        <ul className=' flex flex-col w-full text-weakColor px-14 gap-5'>
                            {mainLinks.map((link: any, index) => (
                                <li key={index} className=' w-full'>
                                    <Link to={`/${link.href}`} className='w-full'>
                                        {link.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    }
                </div>
            </div>
            <div className=' p-6 flex-between'>
                <button
                    type='button'
                    onClick={() => {
                        Cookies.remove('accessToken');
                        navigate("/")
                    }}
                    className='flex items-center xs:text-sm sm:text-base text-red-600 bg-white xs:px-2 sm:px-4 py-2 rounded-md font-semibold xs:gap-1 sm:gap-2'>
                    {t("logout")} <CgLogOut />
                </button>
                <LanguageSwitcher/>
            </div>
        </div>
    )
}

export default Sidebar