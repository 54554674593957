import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaLock, FaUser, FaPhone } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import { signUp } from '../../utils/data';
import { SignUpData } from '../../types';
import toast from 'react-hot-toast';
import { MdEmail } from 'react-icons/md';
const SignUpForm = () => {
    const { t } = useTranslation();
    const [signUpData, setSignUpData] = useState<SignUpData>({ name: '',email:'', phoneNumber: '', password: '' });
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const handleSignUp = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (confirmPassword !== signUpData.password) {
            toast.error("Plaese enter the same password in each filed")
        }
        else {
            try {
                await signUp(signUpData);
            } catch (error) {
                toast.error('Failed to signup. Please check your data.');
            }
        }
    };
    return (
        <div className=' max-container padding-container rounded-md  relative z-30 bg-white xs:w-4/5 md:w-2/5 flex flex-col justify-center py-10 px-8'>
            <p className='text-[24px]  text-black'>{t("hello")}</p>
            <p className='text-[28px] font-bold text-black'>{t("welcome")}</p>
            <form onSubmit={handleSignUp}>
                <div className=" flex mt-8 mb-3 items-center border-[1px] rounded-md border-[#DDD] gap-2 p-3 text-[#a9a9a9]">
                    <FaUser />
                    <input
                        type="text"
                        name="name"
                        placeholder={t("name")}
                        value={signUpData.name}
                        onChange={(e) => setSignUpData({ ...signUpData, name: e.target.value })}
                        required
                        className='text-black w-full focus:outline-none bg-inherit'
                    />
                </div>
                <div className=" flex  mb-3 items-center border-[1px] rounded-md border-[#DDD] gap-2 p-3 text-[#a9a9a9]">
                    <MdEmail />
                    <input
                        type="text"
                        name="email"
                        placeholder={t("email")}
                        value={signUpData.email}
                        onChange={(e) => setSignUpData({ ...signUpData, email: e.target.value })}
                        required
                        className='text-black w-full focus:outline-none bg-inherit'
                    />
                </div>
                <div className=" flex mb-3 items-center border-[1px] rounded-md border-[#DDD] gap-2 p-3 text-[#a9a9a9]">
                    <FaPhone />
                    <input
                        type="number"
                        name="phoneNumber"
                        placeholder={t("phone-number")}
                        value={signUpData.phoneNumber}
                        onChange={(e) => setSignUpData({ ...signUpData, phoneNumber: e.target.value })}
                        required
                        className='text-black w-full focus:outline-none bg-inherit'
                    />
                </div>
                <div className=" flex mb-3 items-center border-[1px] rounded-md border-[#DDD] gap-2 p-3 text-[#a9a9a9]">
                    <FaLock />
                    <input
                        type="password"
                        name="password"
                        placeholder={t("password")}
                        value={signUpData.password}
                        onChange={(e) => setSignUpData({ ...signUpData, password: e.target.value })}
                        required
                        className='text-black w-full focus:outline-none bg-inherit'
                    />
                </div>
                <div className=" flex mb-3 items-center border-[1px] rounded-md border-[#DDD] gap-2 p-3 text-[#a9a9a9]">
                    <FaLock />
                    <input
                        type="password"
                        name="password"
                        placeholder={t("confirm-password")}
                        required
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className='text-black w-full focus:outline-none bg-inherit'
                    />
                </div>
                <input type="submit"
                    value={t("sign-up")}
                    className=" w-full cursor-pointer  mb-3 flex-center bg-primary border-[1px] rounded-md border-none gap-1 py-3 text-white"
                />
                <Link to="/login" className="mt-5 p-3 text-primary">{t("have-account")}</Link>
            </form>
        </div>
    )
}

export default SignUpForm
