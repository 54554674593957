
const Input = ({title,state,setState,type,icon}:any) => {
    return (
        <div className='  w-full py-4'>
            <label className='flex items-center gap-1 text-secondry font-semibold'> 
                <span className=" text-primary">{icon}</span> 
                <p>{title}</p>
                </label>
            <div className='px-5 mt-3  py-3 border-gray-300 bg-gray-200 border-[1px] rounded-md w-full'>
                <input
                    type={type}
                    name="item"
                    value={state}
                    onChange={(e)=>setState(e.target.value)}
                    className='text-black w-full focus:outline-none bg-inherit'
                />
            </div>
        </div>
    )
}

export default Input