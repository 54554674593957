import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { AiFillEdit } from 'react-icons/ai';
import { updateAuctoin } from '../../utils/data';
interface AuctionFieldProps {
    auction?:any
    state?: string | number ;
    statePropName?: string;
    setState?: React.Dispatch<React.SetStateAction<any >>;
    title: string;
    value: string | number;
    edit?:boolean;
}
const AuctionFiled = ({ auction, title, value, edit,statePropName, state, setState }: AuctionFieldProps) => {
    const [change, setChange] = useState(false);
    
    const { t } = useTranslation();
    const handleSave = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const updatedAuction = { ...auction, [statePropName?statePropName:""]:state};
            await updateAuctoin(updatedAuction,auction.id);
            setChange(false)
            window.location.reload()
        } catch (error) {
            console.error('Error updating user data', error);
        }
    };
    return (
        <div className='pt-6 flex gap-5 items-center'>
            <div className={`flex gap-3 ${title === t("auctionDescription") ? "flex-col" : "flex-row items-center"}`}>
                <h2 className=' text-nowrap font-semibold text-lg'>{title} :</h2>
                {(!change) ?
                    <p className=' text-lg text-secondry'>{value}</p> :
                    <form onSubmit={handleSave}  className=' flex items-center gap-2'>
                        <div className='px-5  py-1 border-gray-300 bg-gray-200 border-[1px] rounded-md w-full'>
                            <input
                                type="text"
                                name="item"
                                value={state}
                                onChange={(e) => setState?setState(e.target.value):""}
                                className='text-black w-full focus:outline-none bg-inherit'
                            />
                        </div>
                        <div className=' flex gap-2'>
                            <input type='submit' value={t("save")}  className=' cursor-pointer text-sm font-bold text-secondry'/>
                            <button onClick={() => setChange(false)} className=' text-sm font-bold text-rose-600'>{t("cancel")}</button>
                        </div>
                    </form>
                }
            </div>
            {edit &&
                <div>
                    {
                        (edit && !change) ?
                            <button onClick={() => setChange(true)} className=' font-bold text-primary text-xl'><AiFillEdit /></button> : ""
                    }
                </div>
            }
        </div>
    )
}

export default AuctionFiled