import Hero from './Hero'
import BestAuction from './BestAuction'
import NewAuction from './NewAuction'
import About from './About'
import Plan from './Plan'
import Service from './Service'
import Step from './Step'
import Contact from './Contact'
import EmailAds from './EmailAds'
import Footer from './Footer'
import PlanDetails from './PlanDetails'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

const Home = () => {
    const { hash } = useLocation();

    useEffect(() => {
      if (hash) {
        const element = document.getElementById(hash.replace('#', ''));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [hash]);
    return (
        <div className=' relative'>
            <Hero />
            <BestAuction />
            <NewAuction />
            <About />
            <Service />
            <Step />
            <Plan />
            <Contact />
            <EmailAds />
            <Footer />
            <PlanDetails />
        </div>
    )
}

export default Home
