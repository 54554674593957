import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Auction } from '../../types';
import { bestAuctions, bestAuctions_en, navbarlinks, navbarlinks_en } from '../../constant';
import { useTranslation } from 'react-i18next';
import Logo from '../../components/ui/Logo';
import { IoMdTrendingUp } from "react-icons/io";
import Footer from '../../components/home/Footer';
const AuctionDetails = () => {
    const { t, i18n } = useTranslation();

    const links = i18n.language === "ar" ? navbarlinks : navbarlinks_en;
    const { id } = useParams();
    const globalBestAuctions: Auction[] = i18n.language === "ar" ? bestAuctions : bestAuctions_en;
    const result: Auction | undefined = globalBestAuctions.find((item => item.id === id))
    console.log(result)
    return (
        <div>
            <header className=' bg-secondry py-8 shadow-lg'>
                <div className='flex justify-between  xs:flex-col sm:flex-row gap-5 xs:items-start sm:items-center padding-container max-container'>
                    <Logo />
                    <ul className=' md:flex justify-center items-center py-3 text-lg text-white  gap-5 xs:hidden'>
                        {links.map((link: any, index: number) => (
                            <li key={index} className=' transition-all hover:scale-105 hover:text-primary'>
                                <Link to={link.href}>
                                    {link.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </header>
            <main>
                <div className='padding-container max-container main-prop'>
                    <div className=' flex gap-5 xs:flex-col md:flex-row'>
                        <div className='flex-1'>
                            <div className='w-full h-full'>
                                <img className='w-full h-full' src={`${result?.auctionImages}`} alt='auction-img' loading='lazy' />
                            </div>
                        </div>
                        <div className=' flex-1'>
                            <div className=' w-full'>
                                <div className='flex-between  w-full'>
                                    <h1 className=' text-2xl font-bold text-primary '>{result?.auctionTitle}</h1>
                                    <div className='flex items-center gap-1 text-xl'>
                                        <IoMdTrendingUp className='text-green-600' />
                                        <p>{result?.currentPrice} د.ل</p>
                                    </div>
                                </div>
                                <div className='pt-6'>
                                    <h2 className='text-secondry text-lg pb-2'>{t("auctionDescription")}</h2>
                                    <p className=' text-lg text-weakColor'>{result?.auctionDescription}</p>
                                </div>
                                <div className='pt-6'>
                                    <h2 className=' text-secondry text-lg pb-2'>{t("auctionTerms")}</h2>
                                    <p className=' text-lg text-weakColor'>{result?.auctionTerms}</p>
                                </div>
                                <div className='pt-6 flex gap-x-2'>
                                    <h2 className=' text-secondry text-lg '>{t("minimumIncrement")} :</h2>
                                    <p className=' text-lg text-primary'> {result?.minimumIncrement}د.ل </p>
                                </div>
                                <div className='pt-2 flex gap-x-2'>
                                    <h2 className=' text-secondry text-lg '>{t("quantity")} :</h2>
                                    <p className=' text-lg text-primary'> {result?.quantity} {result?.unitOfMeasure}</p>
                                </div>
                                <div className='pt-2 flex gap-x-2'>
                                    <h2 className=' text-secondry text-lg '>{t("auctionStatus")} :</h2>
                                    <p className=' text-lg text-primary'> {result?.auctionStatus}</p>
                                </div>
                                <div className='grid md:grid-cols-2 xs:grid-cols-1 gap-x-10 gap-y-2 text-lg '>
                                    <div className='pt-2 flex gap-x-2'>
                                        <h2 className=' text-secondry text-lg '>{t("auctionCategory")} :</h2>
                                        <p className=' text-lg text-primary'> {result?.auctionCategory}</p>
                                    </div>
                                    <div className='pt-2 flex gap-x-2'>
                                        <h2 className=' text-secondry text-lg '>{t("auctionLocation")} :</h2>
                                        <p className=' text-lg text-primary'> {result?.auctionLocation}</p>
                                    </div>
                                </div>
                                <div className=' grid md:grid-cols-2 xs:grid-cols-1 gap-x-10 gap-y-2 text-lg pt-6'>
                                    <div className='flex items-center gap-1'>
                                        <h2 className=' text-secondry text-lg '>{t("start-date")} :</h2>
                                        <p>{result?.auctionStartDate}</p>
                                    </div>
                                    <div className='flex items-center gap-1'>
                                        <h2 className=' text-secondry text-lg '>{t("end-date")} :</h2>
                                        <p>{result?.auctionEndDate}</p>
                                    </div>
                                </div>
                                <div className='pt-6 '>
                                    <button className=' bg-secondry px-4 py-2 rounded-md text-white'>
                                        {t("put-price")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default AuctionDetails