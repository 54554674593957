import React, { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { updateUser } from '../../utils/data';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import CardInfo from '../ui/CardInfo';
interface SocialMediaLinks {
    facebookLink?: string;
    instagramLink?: string;
    whatsappLink?: string;
  }
const SocialMedia = ({ edit, setEdit }: any) => {

    const { user, setUser } = useStateContext() as GlobalStateContext;
    const { t } = useTranslation();
    const [facebookLink, setFacebookLink] = useState("");
    const [instagramLink, setInstagramLink] = useState("");
    const [whatsappLink, setWhatsappLink] = useState("");
    const handleSave = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const socialMedia:SocialMediaLinks = {}
            if (facebookLink) socialMedia.facebookLink = facebookLink;
            if (instagramLink) socialMedia.instagramLink = instagramLink;
            if (whatsappLink) socialMedia.whatsappLink = whatsappLink;
            const updatedUser = { ...user, socialMedia };
            await updateUser(updatedUser);
            setUser(updatedUser);
            setEdit(false)
        } catch (error) {
            console.error('Error updating user data', error);
        }
    };
    return (
        <div>
            <div className=' text-lg font-bold  my-2 pb-5  border-b-2'>
                <p>{t("social-media")}</p>
            </div>
            <form onSubmit={handleSave}>
                <div className=' py-5 text-gray-500 '>
                    <div className='flex items-center gap-5'>
                        <div>
                            <div className='w-10 h-10'>
                                <img src="/asset/facebook.png" className=' w-full h-full rounded-full' alt="user" />
                            </div>
                        </div>
                        <CardInfo  state={facebookLink} setState={setFacebookLink} edit={edit} prop={user?.socialMedia?.facebookLink} alt={t("no-link")} />
                        
                    </div>
                    <div className='flex items-center gap-5'>
                        <div>
                            <div className='w-10 h-10'>
                                <img src="/asset/instagram.png" className=' w-full h-full rounded-full' alt="user" />
                            </div>
                        </div>
                        <CardInfo  state={instagramLink} setState={setInstagramLink} edit={edit} prop={user?.socialMedia?.instagramLink} alt={t("no-link")} />
                    </div>
                    <div className='flex  items-center gap-5'>
                        <div>
                            <div className='w-10 h-10'>
                                <img src="/asset/whatsapp.png" className=' w-full h-full rounded-full' alt="user" />
                            </div>
                        </div>
                        <CardInfo  state={whatsappLink} setState={setWhatsappLink} edit={edit} prop={user?.socialMedia?.whatsappLink} alt={t("no-link")} />
                    </div>
                </div>
                <div className='grid w-full pt-6'>
                    {edit ? <input type="submit" value={t("save-changes")} className=' cursor-pointer justify-self-end font-sm px-3 py-1 bg-primary text-white rounded-md ' /> : ""}
                </div>
            </form>
        </div>

    )
}

export default SocialMedia