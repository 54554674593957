import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPhone } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { forgetPassword } from '../../utils/data';

const ForgetPasswordForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [phone, setPhone] = useState('');
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await forgetPassword(phone);
            navigate("verify-number")
        } catch (error) {
            console.error('Failed to reset password:', error);
        }
    };
    return (
        <div className=' max-container padding-container rounded-md  relative z-30 bg-white xs:w-4/5 md:w-2/5 flex flex-col justify-center py-10 px-8'>
            <p className='text-[24px] font-bold text-black'>{t("recover")}</p>
            <div className=' text-black mb-10 mt-2 flex gap-1'>
                <span className=' font-bold text-lg text-red-700'>*</span>
                <p>{t("recover-disc")}</p>
            </div>
            <form onSubmit={handleSubmit} >
                <div className=" flex mb-3 items-center border-[1px] rounded-md border-[#DDD] gap-2 p-3 text-[#a9a9a9]">
                    <FaPhone />
                    <input
                        type="number"
                        name="phoneNumber"
                        placeholder={t("phone-number")}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                        className='text-black w-full focus:outline-none bg-inherit'
                    />
                </div>
                <button type='submit'
                    className=" w-full cursor-pointer  mb-3 flex-center bg-primary border-[1px] rounded-md border-none gap-1 py-3 text-white"
                >{t("send")}</button>
            </form>
        </div>
    )
}

export default ForgetPasswordForm