import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SearchForm = () => {
    const { t } = useTranslation();
    const Navigate= useNavigate();
    return (
        <div>
            <form className='border-[1px] border-white rounded-md '
                >
                <input title='search' type='text' placeholder={t("search-placeholder")}
                    
                    className=' bg-inherit xs:w-44 sm:w-60 py-2 px-4 focus:outline-none' />
                <input onClick={()=>Navigate("/search")} title='search-button' type='submit' value={t("search")}
                    className=' bg-white text-darkColor font-bold cursor-pointer py-2 px-4' />
            </form>
        </div>
    )
}

export default SearchForm
