import { GlobalStateContext, useStateContext } from '../../context/StateContext'
import { IoMdExit } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';
import { subscribeToPlan } from '../../utils/data';
import toast from 'react-hot-toast';

const PlanDetails = () => {
    const { t } = useTranslation();
    const { showPlan, setShowPlan, plan } = useStateContext() as GlobalStateContext;
    const handleSubscribe = async (planId:number| undefined) => {
        try {
            const response = await subscribeToPlan(planId);
            toast.success('Subscription successful:', response);
        } catch (error) {
            console.error('Failed to subscribe:', error);
        }
    };
    return (
        <div className={`fixed bg-[#00000090] z-50 overflow-hidden h-full top-0 right-0 ${showPlan ? "w-full" : "w-0"}`}>
            <div className={`fixed  bg-white z-50 transition-all duration-300 overflow-hidden  h-full top-0 right-0 py-8 ${showPlan ? "xs:w-full  md:w-1/3 " : "xs:w-0 md:w-0 "} `}>
                <div className='grid px-10 h-full'>
                    <div>
                        <div className='flex justify-between mb-5 items-center '>
                            <p className='text-[var(--blue-color)] text-xl font-bold'>{t("your-information")}</p>
                            <button
                                type='button'
                                title='Exit'
                                onClick={() => setShowPlan(false)}
                                className=' text-red-500 text-lg bg-[#EEE] flex-center w-[40px] h-[40px] rounded-full'>
                                <IoMdExit />
                            </button>
                        </div>
                        <div className='flex justify-between items-center text-secondry text-xl font-bold'>
                            <p>{t("will-join")}</p>
                            <p>{plan?.name}</p>
                        </div>
                        <div className=' grid py-10  '>
                                <div>
                                    <p className='text-xl mb-3'>{t("plan-advantage")}</p>
                                    {plan?.features.map((item, index) => (
                                        <div key={index} className='flex gap-2 items-center py-2'>
                                            <div>
                                            <FaCheck className=' text-primary' />
                                            </div>
                                            <p>{item}</p>
                                        </div>
                                    ))}
                                </div>
                                
                            </div>
                    </div>
                    <div className=' self-end bottom-3 w-full ' >
                        <div className='flex justify-between items-center '>
                            <p className='text-[var(--blue-color)] text-xl font-bold'>{t("total-amount")}</p>
                            <p className='text-[var(--blue-color)] text-xl font-bold'>{plan?.price} د.ل</p>
                        </div>
                        <button
                            type="button"
                            onClick={()=> handleSubscribe(plan?.id)}
                            className='text-white w-full mt-5 bg-primary rounded-md py-3  px-6 border-none flex-center' >
                            {t("pay")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlanDetails