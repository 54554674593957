
import { useTranslation } from 'react-i18next';
import SearchForm from '../forms/SearchForm';
import Header from './Header';

const Hero = () => {
    const {t} =useTranslation();
    return (
        <section className='hero h-screen relative'> 
        <Header/>
        <div className='h-full flex-center' id='hero'>
            <div className='overlay'></div>
            <div className=' h-full max-container padding-container text-center absolute w-full text-white flex-center flex-col z-20' >
                <h1 className=' text-5xl font-bold text-primary'  data-aos="fade-up">{t("title")}</h1>
                <p className=' text-3xl font-bold py-5'  data-aos="fade-up" data-aos-delay="400">{t("hero-title")}</p>
                <p className=' text-xl font-bold  py-5'  data-aos="fade-up" data-aos-delay="600">{t("hero-disc")}</p>
                <div className=' py-5 flex-center gap-x-5' data-aos="fade-in" data-aos-delay="800">
                    <SearchForm/>
                </div>
            </div>
        </div>
        </section>
    )
}

export default Hero
