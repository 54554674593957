import { FaProductHunt } from "react-icons/fa";
import { MdSupportAgent ,MdOutlineBugReport ,MdAdsClick } from "react-icons/md";
import { FaFacebook, FaWhatsapp, FaGlobe, FaLinkedin } from 'react-icons/fa';
import { SiDailydotdev } from "react-icons/si";

export const socialMediaLinksMMtech = [
    { name: "Facebook", href: "https://www.facebook.com/mmtechly", icon: <FaFacebook/> },
    { name: "WhatsApp", href: "https://wa.me/218911919187", icon: <FaWhatsapp/> },
    { name: "Website", href: "http://mmtech.ly/", icon: <FaGlobe/> },
    { name: "LinkedIn", href: "https://www.linkedin.com/company/mmtechly?trk=organization_guest_main-feed-card_reshare-text&originalSubdomain=ly", icon: <FaLinkedin/> }
];

export const navbarlinks = [
    { name: "الرئيسية", href: "#hero" },
    { name: "افضل المزادات", href: "#best-auctions" },
    { name: " أحدث المزادات", href: "#latest-auctions" },
    { name: "من نحن !", href: "#about" },
    { name: "خدماتنا", href: "#service" },
    { name: "تواصل معنا", href: "#contact" },
]
export const navbarlinks_en = [
    { name: "Home", href: "#hero" },
    { name: "Best Auctions", href: "#best-auctions" },
    { name: "Latest Offers", href: "#latest-offers" },
    { name: "About us!", href: "#about" },
    { name: "Our Services", href: "#service" },
    { name: "Contact us", href: "#contact" },
]

export const bestAuctions = [
    {
        id:'1',
        auctionTitle: "حديد",
        auctionDescription: "وصف قصير للمزاد الأول",
        auctionImages: ["/asset/slide2.webp"],
        startingPrice: 1000,
        currentPrice: 1500,
        minimumIncrement: 100,
        auctionStartDate: "2024-07-01",
        auctionEndDate: "2024-07-15",
        auctionStatus: "Active",
        quantity: 1,
        unitOfMeasure: "طن",
        auctionCategory: "مواد بناء",
        auctionTerms: "شروط المزاد الأول",
        auctionLocation: "الرياض"
    },
    {
        id:'2',
        auctionTitle: "شقة سكنية",
        auctionDescription: "وصف قصير للمزاد الثاني",
        auctionImages: ["/asset/slide11.jpg"],
        startingPrice: 500000,
        currentPrice: 550000,
        minimumIncrement: 5000,
        auctionStartDate: "2024-07-01",
        auctionEndDate: "2024-07-15",
        auctionStatus: "Active",
        quantity: 200,
        unitOfMeasure: "متر",
        auctionCategory: "عقارات",
        auctionTerms: "شروط المزاد الثاني",
        auctionLocation: "جدة"
    },
    {
        id:'3',
        auctionTitle: "سيارة",
        auctionDescription: "وصف قصير للمزاد الثالث",
        auctionImages: ["/asset/23.webp"],
        startingPrice: 20000,
        currentPrice: 22000,
        minimumIncrement: 1000,
        auctionStartDate: "2024-07-01",
        auctionEndDate: "2024-07-15",
        auctionStatus: "Active",
        quantity: 1,
        unitOfMeasure: "سيارة",
        auctionCategory: "مركبات",
        auctionTerms: "شروط المزاد الثالث",
        auctionLocation: "الدمام"
    },
    {
        id:'4',
        auctionTitle: "حديد",
        auctionDescription: "وصف قصير للمزاد الرابع",
        auctionImages: ["/asset/slide2.webp"],
        startingPrice: 3000,
        currentPrice: 3500,
        minimumIncrement: 200,
        auctionStartDate: "2024-07-05",
        auctionEndDate: "2024-07-17",
        auctionStatus: "Active",
        quantity: 4,
        unitOfMeasure: "طن",
        auctionCategory: "مواد بناء",
        auctionTerms: "شروط المزاد الرابع",
        auctionLocation: "الرياض"
    },
    {
        id:'5',
        auctionTitle: "وحدة سكنية",
        auctionDescription: "وصف قصير للمزاد الخامس",
        auctionImages: ["/asset/slide11.jpg"],
        startingPrice: 300000,
        currentPrice: 350000,
        minimumIncrement: 5000,
        auctionStartDate: "2024-07-05",
        auctionEndDate: "2024-07-18",
        auctionStatus: "Active",
        quantity: 150,
        unitOfMeasure: "متر",
        auctionCategory: "عقارات",
        auctionTerms: "شروط المزاد الخامس",
        auctionLocation: "جدة"
    },
];

export const bestAuctions_en = [
    {
        id:'1',
        auctionTitle: "Iron", 
        auctionDescription: "Short description for the first auction",
        auctionImages: ["/asset/slide2.webp"],
        startingPrice: 1000,
        currentPrice: 1500,
        minimumIncrement: 100,
        auctionStartDate: "2024-07-01",
        auctionEndDate: "2024-07-15",
        auctionStatus: "Active",
        quantity: 1,
        unitOfMeasure: "ton",
        auctionCategory: "Building Materials",
        auctionTerms: "Terms of the first auction",
        auctionLocation: "Riyadh"
    },
    {
        id:'2',
        auctionTitle: "Residential Apartment",
        auctionDescription: "Short description for the second auction",
        auctionImages: ["/asset/slide11.jpg"],
        startingPrice: 500000,
        currentPrice: 550000,
        minimumIncrement: 5000,
        auctionStartDate: "2024-07-01",
        auctionEndDate: "2024-07-15",
        auctionStatus: "Active",
        quantity: 200,
        unitOfMeasure: "meter",
        auctionCategory: "Real Estate",
        auctionTerms: "Terms of the second auction",
        auctionLocation: "Jeddah"
    },
    {
        id:'3',
        auctionTitle: "Car",
        auctionDescription: "Short description for the third auction",
        auctionImages: ["/asset/23.webp"],
        startingPrice: 20000,
        currentPrice: 22000,
        minimumIncrement: 1000,
        auctionStartDate: "2024-07-01",
        auctionEndDate: "2024-07-15",
        auctionStatus: "Active",
        quantity: 1,
        unitOfMeasure: "car",
        auctionCategory: "Vehicles",
        auctionTerms: "Terms of the third auction",
        auctionLocation: "Dammam"
    },
    {
        id:'4',
        auctionTitle: "Iron",
        auctionDescription: "Short description for the fourth auction",
        auctionImages: ["/asset/slide2.webp"],
        startingPrice: 3000,
        currentPrice: 3500,
        minimumIncrement: 200,
        auctionStartDate: "2024-07-05",
        auctionEndDate: "2024-07-17",
        auctionStatus: "Active",
        quantity: 4,
        unitOfMeasure: "ton",
        auctionCategory: "Building Materials",
        auctionTerms: "Terms of the fourth auction",
        auctionLocation: "Riyadh"
    },
    {
        id:'5',
        auctionTitle: "Residential Unit",
        auctionDescription: "Short description for the fifth auction",
        auctionImages: ["/asset/slide11.jpg"],
        startingPrice: 300000,
        currentPrice: 350000,
        minimumIncrement: 5000,
        auctionStartDate: "2024-07-05",
        auctionEndDate: "2024-07-18",
        auctionStatus: "Active",
        quantity: 150,
        unitOfMeasure: "meter",
        auctionCategory: "Real Estate",
        auctionTerms: "Terms of the fifth auction",
        auctionLocation: "Jeddah"
    },
];


export const subscriptionPlans = [
    {
        id:1,
        name: "الخطة الأساسية",
        price: 10,
        features: [
            "إدراج 10 مزادات",
            "دعم فني عبر البريد الإلكتروني",
            "وصول إلى العروض اليومية"
        ],
    },
    {
        
        id:2,
        name: "الخطة المميزة",
        price: 30,
        features: [
            "إدراج 50 مزاداً",
            "دعم فني على مدار الساعة",
            "وصول إلى العروض اليومية",
            "تمييز المزادات المضافة"
        ],
    },
    {
        
        id:3,
        name: "الخطة المتقدمة",
        price: 50,
        features: [
            "إدراج غير محدود للمزادات",
            "دعم فني مخصص",
            "وصول إلى العروض اليومية",
            "تمييز المزادات المضافة",
            "تقارير وتحليلات متقدمة"
        ],
    },
];
export const subscriptionPlans_en = [
    {
        
        id:1,
        name: "Basic Plan",
        price: 10,
        features: [
            "List up to 10 auctions",
            "Email support",
            "Access to daily deals"
        ],
    },
    {
        
        id:2,
        name: "Premium Plan",
        price: 30,
        features: [
            "List up to 50 auctions",
            "24/7 support",
            "Access to daily deals",
            "Highlighted auctions"
        ],
    },
    {
        id:3,
        name: "Advanced Plan",
        price: 50,
        features: [
            "Unlimited auction listings",
            "Dedicated support",
            "Access to daily deals",
            "Highlighted auctions",
            "Advanced reports and analytics"
        ],
    },
];

export const services = [
    {
        name: "تقييم المنتجات",
        description: "نوفر خدمة تقييم دقيقة للمنتجات لضمان جودة المزادات.",
        icon:<FaProductHunt/>
    },
    {
        name: "دعم فني",
        description: "فريق الدعم الفني متواجد على مدار الساعة لحل أي مشكلات تواجهك.",
        icon:<MdSupportAgent/>
    },
    {
        name: "تقارير وتحليلات",
        description: "نوفر لك تقارير مفصلة وتحليلات حول أداء مزاداتك.",
        icon:<MdOutlineBugReport/>
    },
    {
        name: "إعلانات مميزة",
        description: "يمكنك تمييز مزاداتك لتصل إلى أكبر عدد من المستخدمين.",
        icon:<MdAdsClick/>
    },
    {
        name: "عروض يومية",
        description: "استفد من العروض اليومية والخصومات على مختلف المنتجات.",
        icon:<SiDailydotdev/>
    },
];

export const services_en = [
    {
        name: "Product Evaluation",
        description: "We provide accurate product evaluation to ensure auction quality.",
        icon:<FaProductHunt/>
    },
    {
        name: "Technical Support",
        description: "Our technical support team is available 24/7 to resolve any issues.",
        icon:<MdSupportAgent/>
    },
    {
        name: "Reports and Analytics",
        description: "We provide detailed reports and analytics on your auction performance.",
        icon:<MdOutlineBugReport/>
    },
    {
        name: "Featured Ads",
        description: "You can highlight your auctions to reach a larger audience.",
        icon:<MdAdsClick/>
    },
    {
        name: "Daily Deals",
        description: "Take advantage of daily deals and discounts on various products.",
        icon:<SiDailydotdev/>
    },
];

export const auctionSteps = [
    {
        step: 1,
        title: "التسجيل في الموقع",
        description: "قم بإنشاء حساب جديد على موقع مزاد لتتمكن من المشاركة في المزادات.",
    },
    {
        step: 2,
        title: "تصفح المزادات",
        description: "تصفح المزادات المتاحة واختيار المزاد الذي ترغب في المشاركة فيه.",
    },
    {
        step: 3,
        title: "تقديم العروض",
        description: "قدم عروضك على المزادات المختارة وانتظر انتهاء المزاد لمعرفة النتائج.",
    },
    {
        step: 4,
        title: "إتمام الصفقة",
        description: "في حالة الفوز بالمزاد، قم بإتمام عملية الشراء والدفع عبر النظام الآمن لدينا.",
    },
    {
        step: 5,
        title: "استلام المنتج",
        description: "تواصل مع البائع لترتيب استلام المنتج أو شحنه حسب الاتفاق.",
    },
];

export const auctionSteps_en = [
    {
        step: 1,
        title: "Register on the Website",
        description: "Create a new account on Mazad to participate in auctions.",
    },
    {
        step: 2,
        title: "Browse Auctions",
        description: "Browse available auctions and choose the one you want to participate in.",
    },
    {
        step: 3,
        title: "Place Bids",
        description: "Place your bids on selected auctions and wait for the auction to end to see the results.",
    },
    {
        step: 4,
        title: "Complete the Transaction",
        description: "If you win the auction, complete the purchase and payment through our secure system.",
    },
    {
        step: 5,
        title: "Receive the Product",
        description: "Contact the seller to arrange for the receipt or shipping of the product as agreed.",
    },
];




