
import SearchForm from '../forms/SearchForm'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { navbarlinks, navbarlinks_en } from '../../constant';
const Navbar = () => {
    const {i18n} =useTranslation();
    const links = i18n.language === "ar" ? navbarlinks : navbarlinks_en;
    return (
        <nav className='  flex-center' >
            <ul className=' md:flex justify-center items-center py-3 text-lg  gap-5 xs:hidden'>
                {links.map((link, index) => (
                    <li key={index} className=' transition-all hover:scale-105 hover:text-primary'>
                        <Link to={link.href}>
                            {link.name}
                        </Link>
                    </li>
                ))}
            </ul>
            <div className='md:hidden xs:flex'>
                <SearchForm />
            </div>
        </nav>
    )
}

export default Navbar
