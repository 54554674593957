import SignUpForm from '../../components/forms/SignUpForm';
import SignText from '../../components/signtext/SignText';
const SignUp = () => {

    return (
        <div className='flex-center h-[100vh] overflow-hidden'>
                <SignText />
                <SignUpForm />
        </div>
    )
}

export default SignUp
