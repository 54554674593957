import SectionHeader from '../ui/SectionHeader'
import { subscriptionPlans, subscriptionPlans_en } from '../../constant';
import { FaCheck } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { SubscriptionPlan } from '../../types';
import { GlobalStateContext, useStateContext } from '../../context/StateContext';
const Plan = () => {
    const { t, i18n } = useTranslation();
    const { setShowPlan, setPlan } = useStateContext() as GlobalStateContext;
    const plans: SubscriptionPlan[] = i18n.language === "ar" ? subscriptionPlans : subscriptionPlans_en;

    /* 
    ///////remove this comment to fetch plans from backend//////
    const [plans, setPlans] = useState< SubscriptionPlan[]>([]);
    useEffect(() => {
        const loadPlans = async () => {
            try {
                const data = await fetchPlans();
                setPlans(data);
            } catch (error) {
                toast.error('Failed to fetch plans.');
            }
        };
        loadPlans();
    }, []); 
};
*/


    // fuction to send data to planDetails when you click button
    const handlePlan = (plan: SubscriptionPlan) => {
        setPlan(plan)
        setShowPlan(true)
    }
    return (
        <section className=' main-prop overflow-hidden'>
            <div className='max-container padding-container'>

                <SectionHeader title={t("subscribe-plan")} />
                <div className='padding-container border-2 py-10'>
                    <ul className='grid lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1 gap-5'>
                        {plans.map((plan, index) => (
                            <li key={index}
                                data-aos="zoom-out-up" data-aos-delay={`${index + 1}00`}
                                className=' grid shadow-md px-5 py-10 bg-gray-100 plan'>
                                <h3 className=' text-lg font-bold '>{plan.name}</h3>
                                <p className='text-center text-4xl py-5 font-bold'>
                                    {plan.price}
                                    <span className=' text-sm'>{t("monthly")}</span></p>
                                <div>
                                    {plan.features.map((item, index) => (
                                        <div key={index} className='flex gap-2 items-center py-2'>
                                            <FaCheck />
                                            <p>{item}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className='  justify-self-end w-full py-4 flex-center '>
                                    <button onClick={() => handlePlan(plan)} className='px-4 plan-btn py-2 bg-secondry text-lg text-white rounded-md'>
                                        {t("subscribe-now")}
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Plan
