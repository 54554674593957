import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './routes/App';
import "./i18n"
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import Login from './routes/login/Login';
import Home from './components/home/Home';
import SignUp from './routes/signup/SignUp';
import ForgetPassword from './routes/forgetPassword/ForgetPassword';
import Verfication from './routes/forgetPassword/Verfication';
import ForgetPasswordLayout from './routes/forgetPassword/ForgetPasswordLayout';
import ResetPassword from './routes/forgetPassword/ResetPassword';
import { Toaster } from 'react-hot-toast';
import Search from './routes/search/Search';
import AuctionDetails from './routes/auctionDetails/AuctionDetails';
import { StateContext } from './context/StateContext';
import ProfileLayout from './routes/profile/ProfileLayout';
import PersonalDetails from './routes/profile/PersonalDetails';
import SuccessfulAuctions from './routes/profile/SuccessfulAuctions';
import MyAuctions from './routes/profile/MyAuctions';
import AddAuctions from './routes/profile/AddAuctions';
import Notifications from './routes/profile/Notifications';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            { index: true, element: <Home /> },
            { path: "login", element: <Login /> },
            { path: "signUp", element: <SignUp /> },
            { path: "search", element: <Search /> },
            { path: "/auction-id/:id", element: <AuctionDetails /> },
            {
                path: "forget-passward",
                element: <ForgetPasswordLayout />,
                children: [
                    { index: true, element: <ForgetPassword /> },
                    { path: "reset-password", element: <ResetPassword /> },
                    { path: "verify-number", element: <Verfication /> },
                ]
            },
            {
                path: "/profile/:userId",
                element: <ProfileLayout />,
                children: [
                    { index: true, element: <PersonalDetails /> },
                    { path: "personal-details", element: <PersonalDetails /> },
                    { path: "notifications", element: <Notifications /> },
                    { path: "successful-auctions", element: <SuccessfulAuctions /> },
                    { path: "my-auctions", element: <MyAuctions /> },
                    { path: "add-auctions", element: <AddAuctions /> },
                ]
            }
        ]
    },
]);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <StateContext>
            <Toaster />
            <RouterProvider router={router} />
        </StateContext>
    </React.StrictMode>
);
