import SignText from '../../components/signtext/SignText'
import ForgetPasswordForm from '../../components/forms/ForgetPasswordForm'

const ForgetPassword = () => {
  return (
    <div className='flex-center h-screen overflow-hidden'>
      <SignText />
      <ForgetPasswordForm />
    </div>
  )
}

export default ForgetPassword