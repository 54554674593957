import SignText from '../../components/signtext/SignText'
import VerficationForm from '../../components/forms/VerficationForm'

const Verfication = () => {
  return (
    <div className='flex-center h-scrren overflow-hidden'>
      <SignText />
      <VerficationForm />
    </div>
  )
}

export default Verfication