const SectionHeader = ({ title }: { title: string }) => {
    return (
        <div className='flex-center w-full items-center gap-4 mb-10 '>
            <div className=' rounded-full h-1 w-6 bg-darkColor'></div>
            <h2 className=' text-2xl text-secondry font-bold transition-all duration-300 hover:scale-105'>{title}</h2>
            <div className=' rounded-full h-1 w-6 bg-darkColor'></div>
        </div>
    )
}

export default SectionHeader
