import { FormEvent, useState } from 'react'
import Logo from '../../components/ui/Logo'
import { useTranslation } from 'react-i18next';
import { GrMoney } from "react-icons/gr";
import { MdOutlineLocationOn } from "react-icons/md";
import Footer from '../../components/home/Footer';
import { bestAuctions, bestAuctions_en } from '../../constant';
import AuctionCard from '../../components/ui/AuctionCard';
import { Auction, SearchData } from '../../types';
import { searchProduct } from '../../utils/data';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
const Search = () => {

    const { t, i18n } = useTranslation();
    const globalauctions = i18n.language === "ar" ? bestAuctions : bestAuctions_en;
    const navigate = useNavigate();
    const [query, setQuery] = useState('');
    const [auctions, setAuctions] = useState<Auction[]>([]);
    const handleSearch = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const searchData: SearchData = { query };
            const data = await searchProduct(searchData);
            setAuctions(data);
            console.log(auctions)
        } catch (error) {
            toast.error('Failed to search products.');
        }
    };
    return (
        <div>
            <header className=' bg-secondry py-8 shadow-lg'>
                <div className='flex justify-between  xs:flex-col sm:flex-row gap-5 xs:items-start sm:items-center padding-container max-container'>
                    <Logo />
                    <form className='border-[1px] border-white rounded-md ' onSubmit={handleSearch}>
                        <input title='search' type='text' placeholder={t("search-placeholder")}
                            onFocus={() => navigate("/search")}
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            className=' text-white bg-inherit xs:w-44 sm:w-60 py-2 px-4 focus:outline-none' />
                        <input title='search-button' type='submit' value={t("search")}
                            className=' bg-white text-darkColor font-bold cursor-pointer py-2 px-4' />
                    </form>
                </div>
            </header>
            <nav className='py-6'>
                <div className='flex justify-between md:items-center gap-6 md:flex-row xs:flex-col padding-container max-container'>
                    <h2 className=' text-xl text-nowrap'>{t("search-accord")}</h2>
                    <div className=' grid xs:grid-cols-2 md:grid-cols-4 gap-7'>
                        <div className=" flex mb-3 items-center border-[1px] rounded-md border-[#DDD] gap-1 p-3 text-[#a9a9a9]">
                            <GrMoney />
                            <input
                                type="text"
                                name="price-from"
                                placeholder={t("price-from")}
                                required
                                className='text-black w-full focus:outline-none bg-inherit'
                            />
                        </div>
                        <div className=" flex mb-3 items-center border-[1px] rounded-md border-[#DDD] gap-1 p-3 text-[#a9a9a9]">
                            <GrMoney />
                            <input
                                type="text"
                                name="price-to"
                                placeholder={t("price-to")}
                                required
                                className='text-black w-full focus:outline-none bg-inherit'
                            />
                        </div>
                        <div className=" flex mb-3 items-center border-[1px] rounded-md border-[#DDD] gap-1 p-3 text-[#a9a9a9]">
                            <input
                                type="date"
                                name="end-date"
                                placeholder={t("end-date")}
                                required
                                className='text-black w-full focus:outline-none bg-inherit'
                            />
                        </div>
                        <div className=" flex mb-3 items-center border-[1px] rounded-md border-[#DDD] gap-1 p-3 text-[#a9a9a9]">
                            <MdOutlineLocationOn />
                            <input
                                type="text"
                                name="auctionLocation"
                                placeholder={t("auctionLocation")}
                                required
                                className='text-black w-full focus:outline-none bg-inherit'
                            />
                        </div>
                    </div>
                </div>
            </nav>
            <main className='min-h-screen main-prop'>
                <div className=' padding-container max-container'>
                    <div className=' grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5'>
                        {globalauctions.map((auction, index) => (
                            <div key={index}>
                                <AuctionCard item={auction} />
                            </div>
                        ))}
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}
export default Search