import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdTrendingUp } from 'react-icons/io';
import AuctionFiled from './AuctionFiled';
import PopUp from './PopUp';

const MyAuction = ({ auction }: any) => {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const [popUp, setPopUp] = useState(false);
    const [auctionDescription, setAuctionDescription] = useState('');
    const [minimumIncrement, setMinimumIncrement] = useState<number>();
    const [auctionStartDate, setAuctionStartDate] = useState('');
    const [auctionEndDate, setAuctionEndDate] = useState('');
    const [quantity, setQuantity] = useState<number>();
    const [auctionCategory, setAuctionCategory] = useState('');
    const [auctionTerms, setAuctionTerms] = useState('');
    const [auctionLocation, setAuctionLocation] = useState('');

    return (
        <div>
            <div className='px-6 main-prop'>
                <div className='flex gap-5 xs:flex-col md:flex-row'>
                    <div className='flex-1'>
                        <div className='w-full h-full'>
                            <img className='w-full h-full' src={`/asset/hero1.webp`} alt='auction-img' loading='lazy' />
                        </div>
                    </div>
                    <div className='flex-1'>
                        <div className='w-full'>
                            <div className='flex-between w-full'>
                                <h1 className='text-2xl font-bold text-primary'>{auction?.auctionTitle}</h1>
                                <div className='flex items-center gap-1 text-xl'>
                                    <IoMdTrendingUp className='text-green-600' />
                                    <p>{auction?.currentPrice ? auction?.currentPrice : auction?.startingPrice} د.ل</p>
                                </div>
                            </div>
                            <AuctionFiled auction={auction}  state={auctionDescription} statePropName="auctionDescription" setState={setAuctionDescription} edit={edit}  title={t("auctionDescription")} value={auction?.auctionDescription} />
                            <AuctionFiled auction={auction}   state={auctionTerms} statePropName="auctionTerms" setState={setAuctionTerms} edit={edit}  title={t("auctionTerms")} value={auction?.auctionTerms} />
                            <AuctionFiled auction={auction}   state={minimumIncrement} statePropName="minimumIncrement" setState={setMinimumIncrement} edit={edit}  title={t("minimumIncrement")} value={auction?.minimumIncrement + " د.ل "} />
                            <AuctionFiled auction={auction}   state={quantity} statePropName="quantity" setState={setQuantity} edit={edit}  title={t("quantity")} value={auction?.quantity + auction?.unitOfMeasure} />
                            <AuctionFiled title={t("auctionStatus")} value={auction?.auctionStatus} />
                            <div className='grid md:grid-cols-2 xs:grid-cols-1 gap-x-10 gap-y-2 text-lg'>
                                <AuctionFiled auction={auction}   state={auctionCategory}  statePropName="auctionCategory" setState={setAuctionCategory} edit={edit}  title={t("auctionCategory")} value={auction?.auctionCategory} />
                                <AuctionFiled auction={auction}   state={auctionLocation} statePropName="auctionLocation" setState={setAuctionLocation} edit={edit}  title={t("auctionLocation")} value={auction?.auctionLocation} />
                            </div>
                            <div className='grid md:grid-cols-2 xs:grid-cols-1 gap-x-10 gap-y-2 text-lg pt-6'>
                                <AuctionFiled auction={auction}   state={auctionStartDate} statePropName="auctionStartDate" setState={setAuctionStartDate} edit={edit}  title={t("start-date")} value={auction?.auctionStartDate} />
                                <AuctionFiled auction={auction}   state={auctionEndDate} statePropName="auctionEndDate" setState={setAuctionEndDate} edit={edit}  title={t("end-date")} value={auction?.auctionEndDate} />
                            </div>
                            <div className='pt-6 grid'>
                                <div className='flex gap-4 justify-self-end'>
                                    <button
                                        type='button'
                                        onClick={() => setEdit(!edit)}
                                        className='bg-blue-600 text-white px-4 py-2 rounded-md'>
                                        {t("edit")}
                                    </button>
                                    <button
                                        type='button'
                                        onClick={() => setPopUp(true)}
                                        className='bg-red-600 text-white px-4 py-2 rounded-md'>
                                        {t("delete")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {popUp&&<PopUp setPopUp={setPopUp} id={auction.id}/>}
        </div>
    );
};

export default MyAuction;
