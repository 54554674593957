import { services, services_en } from '../../constant';
import SectionHeader from '../ui/SectionHeader';
import { useTranslation } from 'react-i18next';

const Service = () => {
    const {t,i18n} =useTranslation();
    const globalServices = i18n.language === "ar" ? services : services_en;
    return (
        <section className='main-prop overflow-hidden' id='service'>
            <div className='max-container padding-container'>
                <SectionHeader title={t("services")} />
                <ul className=' grid lg:grid-cols-5 md:grid-cols-3 xs:grid-cols-2 gap-5'>
                    {globalServices.map((service,index)=>(
                        <li key={index}
                        data-aos="fade-up" data-aos-delay={`${index+1}00`}
                        className='grid bg-gray-100 p-5 rounded-sm border-t-4 border-secondry transition-all duration-300 hover:shadow-md'>
                            <h3 className='text-lg font-bold mb-3'>{service.name}</h3>
                            <p className=' text-gray-500 mb-5'>{service.description}</p>
                            <p className='  justify-self-end text-3xl font-bold text-primary'>{service.icon}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default Service
