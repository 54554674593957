import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import { fetchAuctions } from '../../utils/data';
import MyAuction from '../../components/profile/MyAuction';
import { Link } from 'react-router-dom';
import Loader from '../../components/ui/Loader';
 import { Auction } from '../../types';
const MyAuctions = () => {
    const { t } = useTranslation();

    const { user } = useStateContext() as GlobalStateContext;
    const [auctions, setAuctions] = useState<Auction[]>([]);
    const [loading, setLoading] = useState(true); 
    useEffect(() => {
        const getAuctions = async () => {
            if (user) {
                try {
                    const AuctionsData = await fetchAuctions(user.id);
                    setAuctions(AuctionsData);
                } catch (error) {
                    console.error('Error fetching Auctions data', error);
                } finally {
                    setLoading(false); // Stop loading when data fetching is done
                }
            }

        };
        getAuctions();
        // eslint-disable-next-line
    }, [user?.id]);
    if (loading) {
        return <Loader/>; 
    }
    return (
        <div className='bg-gray-200 pb-10'>
            {auctions.length!==0 ?
                <div className='mx-6  '>
                    <div className=' flex-between bg-white p-5 mb-5 border-s-8 border-primary w-full'>
                        <h1 className=' font-bold text-lg  '>{t("my-auctions")}</h1>
                    </div>
                    <div className='bg-white py-5 px-5'>
                        <div className=' text-lg font-bold my-2 pb-5 border-b-2'>
                            <p>{t("my-auction-title")}</p>
                        </div>
                        <ul>
                            {auctions?.map((auction, index) => (
                                <li key={index} className=' border-b-2'><MyAuction auction={auction} /></li>
                            ))}
                        </ul>
                    </div>
                </div>
                :
                <div className=' flex-center text-center flex-col'>
                    <div className=' xs:w-72 sm:w-96 xs:h-72 sm:h-96'>
                        <img className='w-full h-full' src='/asset/no1.png'  alt='not-found' />
                    </div>
                    <p className='py-5'>{t("no-auctions")}</p>
                    <Link className=' text-white bg-secondry px-5 py-2 rounded-full'
                        to={`/profile/${user?.id}/add-auctions`}>{t("add-auction")}</Link>
                </div>
            }
        </div>
    )
}

export default MyAuctions