import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import PersonalDetailsCard from '../../components/profile/PersonalDetailsCard';
import SocialMedia from '../../components/profile/SocialMedia';

const PersonalDetails = () => {

    const [edit, setEdit] = useState(false)
    const { t } = useTranslation();
    return (
        <div className=' bg-gray-200 px-8'>
            <div className=' flex-between bg-white p-3 border-s-8 border-primary w-full'>
                <h1 className=' font-bold text-lg  '>{t("personal-edit")}</h1>
                <button
                    type='button'
                    onClick={() => setEdit(!edit)}
                    className=' bg-primary rounded-md text-white px-5 py-2'
                >{t("edit")}</button>
            </div>
            <div className=' flex xs:flex-col lg:flex-row gap-8'>
                <div className=' xs:w-full lg:w-2/3 my-10 px-6 py-3 bg-white'>
                    <PersonalDetailsCard setEdit={setEdit} edit={edit} />
                </div>
                <div className='xs:w-full lg:w-1/3 my-10 px-6 py-3 h-fit bg-white'>
                    <SocialMedia edit={edit} setEdit={setEdit} />
                </div>
            </div>
        </div>
    )
}

export default PersonalDetails