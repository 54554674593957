import React, { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { updateUser, updateUserImage } from '../../utils/data';
import CardInfo from '../ui/CardInfo';
import { GlobalStateContext, useStateContext } from '../../context/StateContext';
interface PersonalDetailsCardProps{
    edit:boolean;
    setEdit:React.Dispatch<React.SetStateAction<boolean>>
}
const PersonalDetailsCard = ({ edit, setEdit }: PersonalDetailsCardProps) => {

    const { user, setUser } = useStateContext() as GlobalStateContext;
    const [name, setName] = useState('');
    const [company, setCompany] = useState("");
    const [address, setAddress] = useState("");
    const [additionalInfo, setAdditionalInfo] = useState("");

    const handleImageUpload = async (e: any) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        try {
            const updatedUser = await updateUserImage(user.id, formData);
            console.log('User updated:', updatedUser);
        } catch (error) {
            console.error('Error updating user image:', error);
        }
    };
    const handleSave = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const updatedUser = { ...user, name, company, address, additionalInfo };
            await updateUser(updatedUser);
            setUser(updatedUser);
            setEdit(false)
        } catch (error) {
            console.error('Error updating user data', error);
        }
    };
    const { t } = useTranslation();
    return (
        <div>
            <div className=' text-lg font-bold  my-2 pb-5  border-b-2'>
                <p>{t("personal-info")}</p>
            </div>
            <div className=' flex-between'>
                <div className=' flex items-center gap-6'>
                    <div>
                        <div className='w-32 h-32 relative'>
                            <div className='bg-[#00000090] absolute w-full h-full rounded-full '></div>
                            <img src="/asset/person.jpg" className=' w-full h-full rounded-full' alt="user" />
                        </div>
                    </div>
                    <div >
                        <p className=' text-lg font-semibold pb-2'>{t("personal-pic")}</p>
                        <p className=' text-sm text-gray-400'>{t("we-advise")}</p>
                    </div>
                </div>
                <div>
                    {edit ? <label>
                        <div className=" bg-primary text-white px-4 py-2 rounded-md cursor-pointer">
                            {t("edit")}
                        </div>
                        <input
                            type="file"
                            name="upload"
                            onChange={handleImageUpload}
                            className="h-0 w-0"
                        />
                    </label> : ""
                    }

                </div>
            </div>
            <form onSubmit={handleSave} >
                <CardInfo title={t("username")} state={name} setState={setName} edit={edit} prop={user?.name} alt={t("edit-name")} />
                <CardInfo title={t("email")} prop={user?.email} />
                <CardInfo title={t("phone-number")} prop={user?.phoneNumber} />
                <div className=' flex gap-5 xs:flex-col md:flex-row'>
                    <CardInfo title={t("company-name")} state={company} setState={setCompany} edit={edit} prop={user?.company} alt={t("add-company")} />
                    <CardInfo title={t("address")} state={address} setState={setAddress} edit={edit} prop={user?.address} alt={t("add-address")} />
                </div>
                <CardInfo title={t("additinal-info")} state={additionalInfo} setState={setAdditionalInfo} edit={edit} prop={user?.additionalInfo} alt={t("add-additinal-info")} />
                <div className='grid w-full pt-6'>
                    {edit ? <input type="submit" value={t("save-changes")} className=' cursor-pointer justify-self-end font-sm px-3 py-1 bg-primary text-white rounded-md ' /> : ""}
                </div>
            </form>
        </div>
    )
}

export default PersonalDetailsCard