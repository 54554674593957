import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import { LoginData, SignUpData, SearchData, Auction } from '../types';
import { jwtDecode } from 'jwt-decode';
const API_URL = 'https://fake-api-123.up.railway.app';


//Login Function
export const login = async (loginData: LoginData) => {
    try {
        const response = await fetch(`${API_URL}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            toast.error(` ${errorData}`);
        }
        const data = await response.json();
        toast.success(`login successfully`);
        Cookies.set('accessToken', data.accessToken, { expires: 7 });
        window.location.href = ("/")
        return
    } catch (error) {
        console.error(`${error}, ": Failed to Login`)
    }
};


//--------------------------------------------
//Sign up Function
export const signUp = async (signUpData: SignUpData) => {
    try {
        const response = await fetch(`${API_URL}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(signUpData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            toast.error(` ${errorData}`);
        }

        const data = await response.json();
        toast.success(`SignUp successfully`);
        Cookies.set('accessToken', data.accessToken, { expires: 7 });
        window.location.href = ("/")
        return data;
    } catch (error) {
        console.error(`${error}, ": Failed to Sign Up`);
    }
};

//--------------------------------------------
// fetch user 
export const fetchUser = async () => {
    const token = Cookies.get('accessToken');
    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            const userId = decodedToken.sub;
            const response = await fetch(`${API_URL}/users/${userId}`);
            const userData = await response.json();
            return userData;
        } catch (error) {
            console.error('Failed to fetch user data', error);
        }
    }
};

//--------------------------------------------
// update user 
export const updateUser = async (user: any) => {
    const token = Cookies.get('accessToken');
    if (token) {
        try {
            const response = await fetch(`${API_URL}/users/${user.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(user),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const updatedUser = await response.json();
            return updatedUser;
        } catch (error) {
            console.error('Failed to update user data', error);
            throw error;
        }
    } else {
        throw new Error('No access token found');
    }
};

//--------------------------------------------
// update image

export const updateUserImage = async (userId: number, formData: FormData) => {
    const response = await fetch(`${API_URL}/users/${userId}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'
        },
        body: formData,
    });
    return response.json();
};
//------------------------------------------
// upload auction
export const uploadAuction = async (auctionData: Auction) => {
    const token = Cookies.get('accessToken');
    const response = await fetch(`${API_URL}/auctions`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(auctionData)
    });
    return response.json();
};

export const deleteAuction = async (auctionId: string | number) => {
    const token = Cookies.get('accessToken');
    const response = await fetch(`${API_URL}/auctions/${auctionId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
    if (response.ok) {
        toast.success('Auction deleted successfully');
    } else {
        toast.error('Failed to delete auction');
    }
}
//---------------------------------------
// fetch my auctions
export const fetchAuctions = async (userId: number | string) => {
    try {
        const response = await fetch(`${API_URL}/auctions?userId=${userId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching auctions:', error);
    }
};
//--------------------------------------------
// Edit Auction
export const updateAuctoin = async (auction: any,id:string|number) => {
    const token = Cookies.get('accessToken');
    try {
        const response = await fetch(`${API_URL}/auctions/${auction.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(auction),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const updatedAuction = await response.json();
        return updatedAuction;
    } catch (error) {
        console.error('Failed to update user data', error);
        throw error;
    }

};

//--------------------------------------------
// function for forget Password
export const forgetPassword = async (phone: string) => {
    try {
        const response = await fetch(`${API_URL}/forget-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(phone),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`${error}, "فشل في ارسال كلمة المرور"`);

    }
};

//--------------------------------------------
// function for verfiy number
export const verfiyNumber = async (code: string) => {
    try {
        const response = await fetch(`${API_URL}/verfiy-number`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(code),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`${error}, "كود غير صحيح "`);

    }
};










//--------------------------------------------
//Function for search
export const searchProduct = async (searchData: SearchData) => {
    try {
        const response = await fetch(`${API_URL}/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(searchData),
        });

        if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data.auctions;
    } catch (error) {
        console.error(`${error}, ": Failed to search products`);
        return [];
    }
};


//--------------------------------------------
//Function for fetch Best Auctions
export const fetchBestAuctions = async () => {
    try {
        const response = await fetch(`${API_URL}/best-auctions`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`${error}, ": فشل في جلب أفضل المزادات"`);
        return [];
    }
};


//--------------------------------------------
//Function for fetch Newest Auctions
export const fetchNewestAuctions = async () => {
    try {
        const response = await fetch(`${API_URL}/newest-auctions`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`${error}, ": فشل في جلب أحدث المزادات"`);
        return [];
    }
};

//--------------------------------------------
//Function for fetch Plans
export const fetchPlans = async () => {
    try {
        const response = await fetch(`${API_URL}/plans`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`${error}, ": Failed to fetch plans"`);
        return [];
    }
};


export const subscribeToPlan = async (planId: number | undefined) => {
    try {
        const response = await fetch(`${API_URL}/subscribe`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(planId),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`${error}, ": فشل في عملية الاشتراك في الباقة"`);
        throw error;
    }
};