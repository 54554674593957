import { useState } from "react";
import Lottie from "lottie-react";
import contact from "../../animation/contact.json";
import { useTranslation } from "react-i18next";
const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const { t } = useTranslation();

    return (
        <div className="main-prop " id="contact">
            <div className="max-container padding-container flex justify-center relative main-prop bg-secondry xs:h-full lg:h-[110vh] overflow-hidden">
                <div className=" flex gap-10 justify-between items-center xs:flex-col lg:flex-row xs:w-full md:w-5/6 h-full">
                    <div className="form-container relative  xs:w-[350px] md:w-[500px] ">
                        <div className="absolute inset-0 bg-gradient-to-r from-weakColor to-darkColor shadow-lg transform -rotate-6 rounded-md"></div>
                        <div className="relative py-10 px-8 bg-white shadow-lg rounded-md">
                            <p className="text-[32px] text-primary font-bold mb-10 text-center text-nowrap">
                                {t("contactTitle")}
                            </p>
                            <form
                                className="flex flex-col mx-auto xs:w-[300px] md:w-[450px] "
                                
                            >
                                <div className="flex  mb-3 items-center border-[1px] rounded-md border-[#DDD] gap-1 p-3 text-[#a9a9a9]">
                                    <input
                                        type="text"
                                        title="name"
                                        value={name}
                                        required
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                        placeholder={t("namePlaceholder")}
                                        className="text-black focus:outline-none w-full bg-inherit"
                                    />
                                </div>
                                <div className="flex  mb-3 items-center border-[1px] rounded-md border-[#DDD] gap-1 p-3 text-[#a9a9a9]">
                                    <input
                                        type="email"
                                        title="email"
                                        value={email}
                                        placeholder={t("emailPlaceholder")}
                                        required
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="text-black focus:outline-none w-full bg-inherit"
                                    />
                                </div>
                                <div className="flex  mb-3 items-center border-[1px] rounded-md border-[#DDD] gap-1 p-3 text-[#a9a9a9]">
                                    <textarea
                                        name="message"
                                        title="message"
                                        value={message}
                                        placeholder={t("messagePlaceholder")}
                                        onChange={(e) => setMessage(e.target.value)}
                                        className="text-black focus:outline-none w-full bg-inherit max-h-[150px]"
                                    ></textarea>
                                </div>
                                <input
                                    type="submit"
                                    value={t("submitButton")}
                                    className=" cursor-pointer bg-primary text-white text-lg rounded-md border-none py-3 "
                                />
                            </form>
                        </div>
                    </div>
                    <div className="relative flex-1 z-10 pb-20">
                        <Lottie animationData={contact} loop={true} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
