import { useTranslation } from 'react-i18next';
import { FaLock } from 'react-icons/fa';
import { Link } from 'react-router-dom'

const ResetPasswordForm = () => {
    const { t } = useTranslation();
    return (
        <div className=' max-container padding-container rounded-md  relative z-30 bg-white xs:w-4/5 md:w-2/5 flex flex-col justify-center py-10 px-8'>
            <p className='text-[24px] font-bold text-black'>{t("recover")}</p>
            <div className=' text-black mb-10 mt-2 flex gap-1'>
                <span className=' font-bold text-lg text-red-700'>*</span>
                <p>{t("reset-password")}</p>
            </div>
            <form >
            <div className=" flex mb-3 items-center border-[1px] rounded-md border-[#DDD] gap-2 p-3 text-[#a9a9a9]">
                    <FaLock />
                    <input
                        type="password"
                        name="password"
                        placeholder={t("password")}
                        required
                        className='text-black w-full focus:outline-none bg-inherit'
                    />
                </div>
                <div className=" flex mb-3 items-center border-[1px] rounded-md border-[#DDD] gap-2 p-3 text-[#a9a9a9]">
                    <FaLock />
                    <input
                        type="password"
                        name="password"
                        placeholder={t("confirm-password")}
                        required
                        className='text-black w-full focus:outline-none bg-inherit'
                    />
                </div>
                <Link to={"/"}
                    className=" w-full cursor-pointer  mb-3 flex-center bg-primary border-[1px] rounded-md border-none gap-1 py-3 text-white"
                >{t("send")}</Link>
            </form>
        </div>
    )
}

export default ResetPasswordForm