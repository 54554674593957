import React, { createContext, useContext, useState } from "react";
import { SubscriptionPlan } from "../types";
export interface GlobalStateContext {
    plan: SubscriptionPlan| undefined;
    setPlan: React.Dispatch<React.SetStateAction<SubscriptionPlan | undefined>>;
    showPlan: boolean;
    setShowPlan: React.Dispatch<React.SetStateAction<boolean>>;
    user:any;
    setUser:React.Dispatch<React.SetStateAction<any>>;
}
const Context = createContext<GlobalStateContext | null>(null);
interface StateContextProps {
    children: React.ReactNode;
}
export const StateContext = ({ children }: Readonly<StateContextProps>) => {
    const [plan, setPlan] = useState<SubscriptionPlan>();
    const [showPlan, setShowPlan] = useState(false);
    const [user, setUser] = useState();
    return (
        <Context.Provider value={{ user,setUser, plan, setPlan,showPlan,setShowPlan }}>
            {children}
        </Context.Provider>
    );
}
export const useStateContext=()=>useContext(Context);