import { navbarlinks, navbarlinks_en,  services, services_en, socialMediaLinksMMtech } from '../../constant';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../ui/LanguageSwitcher';

const Footer = () => {
    const { t, i18n } = useTranslation();
    const links = i18n.language === "ar" ? navbarlinks : navbarlinks_en;
    const globalServices = i18n.language === "ar" ? services : services_en;
    return (
        <footer className=' bg-secondry text-white'>
            <div className=' main-prop max-container padding-container'>
                <div className='grid items-start gap-10 lg:grid-cols-4 md:grid-cols-2 xs:grid-cols-1'>
                    <div>
                        <h2 className=' text-3xl font-bold mb-3'>{t("title")}</h2>
                        <p className='text-gray-300'>{t("footer-disc")}</p>
                        <div className=' mt-3'>
                            <LanguageSwitcher />
                        </div>

                    </div>
                    <div>
                        <h2 className='text-xl mb-3'>{t("contactTitle")}</h2>
                        <div className="">
                            <p>+218911919187</p>
                            <p>email@gamil.com</p>
                            <p>2email@gamil.com</p>
                        </div>
                    </div>
                    <div>
                        <h2 className='text-xl mb-3'>{t("important-links")}</h2>
                        <ul className=' '>
                            {links.map((link: any, index) => (
                                <li key={index} className='mb-1 '>
                                    <Link to={link.href} className=' transition-all duration-300 hover:scale-105 w-full'>
                                        {link.name}
                                    </Link>
                                </li>
                            ))
                            }
                        </ul>
                    </div>
                    <div>
                        <h2 className='text-xl mb-3'>{t("services")}</h2>
                        <ul>
                            {globalServices.map((service, index) => (
                                <li key={index} className='mb-1 '>
                                    {service.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className='border-t-[1px] border-gray-300 py-5'>
                <div className='padding-container max-container flex-between xs:flex-col md:flex-row gap-8 '>
                    <div className='xs:text-center md:text-start'>{t("copy-rights")}&copy;</div>
                    <ul className='flex gap-5 text-2xl'>
                        {
                            socialMediaLinksMMtech.map((item, index) => (
                                <li key={index}>
                                    <Link to={item.href}>{item.icon}</Link>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>

        </footer>
    )
}

export default Footer
