import { useTranslation } from "react-i18next";

const About = () => {
    const {t,i18n}= useTranslation();
    return (
        <section className='main-prop' id='about'>
            <div className='max-container  padding-container'>
                <div className='text-white bg-secondry gap-10 flex-between md:flex-row xs:flex-col  padding-container main-prop shadow-xl'>
                    <div className=' flex-1 xs:text-center md:text-start'>
                        <p className=' text-2xl font-bold mb-3 text-primary'>{t("About")}</p>
                        <p>{t("about-disc")}</p>
                    </div>
                    <div className='flex-1 grid relative '>
                        <div data-aos="fade-in" className={`md:w-96 sm:w-80 xs:w-64 md:h-64 xs:h-52 border-8 border-weakColor absolute -top-7 ${i18n.language === "ar" ? "xs:-left-4 md:-left-7" : "xs:-right-4 md:-right-7"}  z-10`}></div>
                        <img
                            data-aos="fade-up" data-aos-delay="400"
                            className=' xs:h-full md:h-5/6 justify-self-end relative z-20' src={"/asset/about.webp"} alt="pic" loading="lazy"/>
                    </div>
                </div>

            </div>
        </section>
    )
}
export default About 