import { useEffect, useState } from 'react'
import Sidebar from '../../components/profile/Sidebar'
import Logo from '../../components/ui/Logo'
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { fetchUser } from '../../utils/data';
import { useStateContext, GlobalStateContext } from '../../context/StateContext';
import { FaBars } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import Loader from '../../components/ui/Loader';
import ScrollToTop from '../../components/home/ScrollToTop';

const ProfileLayout = () => {
    const [open, setOpen] = useState(false);
    const [loading,setLoading]=useState(false)
    const { t } = useTranslation();
    const { user, setUser } = useStateContext() as GlobalStateContext;
    useEffect(() => {
        if (!user) {
            setLoading(true)
            const getUserData = async () => {
                try {
                    const userData = await fetchUser();
                    setUser(userData);
                } catch (error) {
                    console.error('Error fetching user data', error);
                }finally{
                    setLoading(false)
                }
            };
            getUserData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if(loading) return <div className='h-screen'><Loader/></div>
    return (
            <div className='flex h-screen text-white'>
            {
                open ?
                    <div className={`fixed bg-[#00000090] z-40 md:hidden xs:flex w-full  h-full top-0 left-0 `}>
                    </div> : ""
            }
            <div className={` transition-all ease-linear fixed right-0 top-0  z-50  md:w-2/6 lg:w-1/5  h-full bg-secondry ${open ? "xs:w-4/6" : "xs:w-0 overflow-hidden"}`}>
                <button type='button' onClick={() => setOpen(false)} title='close menu' className={` ${open ? "flex" : "hidden"} absolute top-10 -left-10 z-50 border-[1px] p-1 text-xl`}>
                    <IoMdClose />
                </button>
                <Sidebar user={user} setOpen={setOpen} />
            </div>
            <div className=' absolute left-0 h-full xs:w-full md:w-4/6 lg:w-4/5'>
                <div className=' fixed left-0 xs:w-full md:w-4/6 lg:w-4/5 z-30 shadow-md top-0 bg-white  px-6 py-7 flex-between '>
                    <Logo />
                    <div className=' flex items-center gap-5 text-lg font-bold text-secondry'>
                        <p>{t("balance")}: XXX</p>
                        <button type='button' onClick={() => setOpen(true)} title='open menu' className=' xs:flex  font-medium md:hidden border-[1px] border-secondry p-1 text-xl'>
                            <FaBars />
                        </button>
                    </div>
                </div>
                <div className='mt-20 text-black h-full  bg-gray-200  main-prop'>
                    <Outlet />
                </div>
            </div>
            <ScrollToTop/>
        </div>
    )
}

export default ProfileLayout