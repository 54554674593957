import { PiUniteDuotone } from "react-icons/pi";
import { MdOutlineNumbers } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Auction } from "../../types";

type AuctionCardProps = {
    item: Auction;
  };
const AuctionCard = ( {item}:AuctionCardProps) => {
    const {t} =useTranslation();
    return (
        <div className=' p-2 rounded-md overflow-hidden bg-secondry'>
            <div className='h-56 overflow-hidden rounded-lg'>
                <img className='h-full w-full' src={`${item.auctionImages}`} alt="pic" loading="lazy"/>
            </div>
            
            <div className='bg-secondry text-white py-3 px-2'>
                <h4 className=' text-lg font-bold text-primary py-2'>{item.auctionTitle}</h4>
                <p className='  mb-2 '>{item.auctionDescription}</p>
                <div className=' flex items-center justify-between px-2 text-lg'>
                    <div className='flex items-center gap-1'>
                        <MdOutlineNumbers className='text-primary' />
                        <p>{item.quantity}</p>
                    </div>
                    <div className='flex items-center gap-1'>
                        <PiUniteDuotone className='text-primary' />
                        <p>{item.unitOfMeasure}</p>
                    </div>
                </div>
                <div className='py-2 cursor-pointer rounded-md  bg-darkColor w-full text-center my-4'>
                    <Link className='w-full' to={`/auction-id/${item.id}`} >{t("show-details")}</Link>
                </div>
            </div>
        </div>
    )
}

export default AuctionCard
