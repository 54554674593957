import { navbarlinks, navbarlinks_en } from '../../constant';
import { Link } from 'react-router-dom';
import React from 'react'
import { IoMdClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
interface MobileNavProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const MobileNavbar: React.FC<MobileNavProps> = ({ open, setOpen }) => {
    
    const {i18n} =useTranslation();
    const links = i18n.language === "ar" ? navbarlinks : navbarlinks_en;
    return (
        <div className={`fixed bg-[#00000090] z-50 md:hidden xs:flex  h-full top-0 left-0 ${open?"w-full":"w-0"}`}>
            <div className={`fixed bg-darkColor z-50 transition-all duration-300  h-full top-0 left-0 py-8 ${open?"w-4/6 overflow-visible":"w-0 overflow-hidden"} `}>
                <button type='button' onClick={()=>setOpen(false)} title='close menu' className= {` ${open?"flex":"hidden"} absolute -right-12 z-50 border-[1px] p-1 text-xl`}>
                    <IoMdClose />
                </button>
                <ul className=' flex flex-col py-3 px-6 gap-5 '>
                    {links.map((link, index) => (
                        <li key={index} className=' transition-all hover:scale-105'>
                            <Link to={link.href} className='w-full text-start'>
                            <button className="w-full text-start" type="button" onClick={()=>setOpen(false)}>
                                {link.name}
                                </button>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default MobileNavbar
