import React from 'react'
import { useTranslation } from 'react-i18next'
import { deleteAuction } from '../../utils/data';
import toast from 'react-hot-toast';
interface PopUpProps {
    setPopUp: React.Dispatch<React.SetStateAction<boolean>>;
    id: number | string
}
const PopUp = ({ setPopUp, id }: PopUpProps) => {
    const { t } = useTranslation();
    const handleDelete = async () => {
        try {
            await deleteAuction(id);
            window.location.reload()
        } catch {
            toast.error("Auction not deleted ")
        }


    }
    return (
        <div className='fixed xs:w-full md:w-4/6 lg:w-4/5 inset-0 bg-[#000000e3] flex items-center justify-center '>
            <div className=' xs:w-72 sm:w-96 bg-white p-5'>
                <p className=' font-bold mb-5'>{t("delete-text")}</p>
                <div className=' grid'>
                    <div className=' justify-self-end flex gap-5 '>
                        <button onClick={() => setPopUp(false)} className='text-sm font-bold text-secondry' >{t("cancel")}</button>
                        |
                        <button onClick={handleDelete} className=' text-sm font-bold text-rose-600'>{t("delete")}</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PopUp