import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ar_translation from "./locales/ar/translation.json";
import en_translation from "./locales/en/translation.json";

const resources = {
    ar: {
        translation:ar_translation
    },
    en: {
        translation: en_translation
    },
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'ar',
        interpolation: {
            escapeValue: false,
        }
    });


export default i18n;