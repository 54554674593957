import React from 'react'
import { useTranslation } from 'react-i18next'
import { Triangle } from 'react-loader-spinner'

const Loader = () => {
    const {t}=useTranslation();
    return (
        <div className="flex-center w-full h-full">
            <div className=' flex flex-col gap-4'>
                <Triangle
                visible={true}
                height="80"
                width="80"
                color="#ffa600"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
            /> 
            <p className=' text-lg font-semibold text-secondry'>{t("loading")}</p>
            </div>
            

        </div>
    )
}

export default Loader