import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '../../components/ui/Input';
import { MdCategory, MdDescription, MdOutlineProductionQuantityLimits, MdOutlineSubtitles } from 'react-icons/md';
import { ImPriceTag } from "react-icons/im";
import { VscDebugStepOut } from "react-icons/vsc";
import { BsCalendarDate } from "react-icons/bs";
import { AiOutlineDeploymentUnit } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
import { TbAlignBoxCenterMiddle } from "react-icons/tb";
import { Auction } from '../../types';
import { uploadAuction } from '../../utils/data';
import { GlobalStateContext, useStateContext } from '../../context/StateContext';
import { useNavigate } from 'react-router-dom';

const AddAuctions = () => {
    const { t } = useTranslation();
    const navigate=useNavigate();
    const { user } = useStateContext() as GlobalStateContext;
    const [title, setTitle] = useState("")
    const [startPrice, setStartPrice] = useState<number | undefined>(undefined);
    const [description, setDescription] = useState('');
    const [images, setImages] = useState<string[]>([]);
    const [minimumIncrement, setMinimumIncrement] = useState<number | undefined>(undefined);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [quantity, setQuantity] = useState<number | undefined>(undefined);
    const [unitOfMeasure, setUnitOfMeasure] = useState('');
    const [category, setCategory] = useState('');
    const [terms, setTerms] = useState('');
    const [location, setLocation] = useState('');
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const newAuction: Auction = {
            auctionTitle: title,
            auctionDescription: description,
            auctionImages: images,
            startingPrice: startPrice || 0,
            minimumIncrement: minimumIncrement || 0,
            auctionStartDate: startDate,
            auctionEndDate: endDate,
            quantity: quantity || 0,
            unitOfMeasure: unitOfMeasure,
            auctionCategory: category,
            auctionTerms: terms,
            auctionLocation: location,
            userId: user.id
        };
        try {
            await uploadAuction(newAuction);
            navigate(`/profile/${user.id}/my-auctions`)
        } catch (error) {
            console.error('Error uploading auction:', error);
        }
    };
    return (
        <div className='bg-gray-200 pb-10'>
            <div className='mx-6  '>
                <div className=' flex-between bg-white p-5 mb-5 border-s-8 border-primary w-full'>
                    <h1 className=' font-bold text-lg  '>{t("add-auction")}</h1>
                </div>
                <div className='bg-white py-5 px-5'>
                    <div className=' text-lg font-bold my-2 pb-5 border-b-2'>
                        <p>{t("enter-auction")}</p>
                    </div>
                    <form className='flex flex-col gap-5 ' onSubmit={handleSubmit}>
                        <div className='grid xs:grid-cols-1 md:grid-cols-2 gap-6'>
                            <Input icon={<MdOutlineSubtitles />} title={t("auction-name")} state={title} setState={setTitle} />
                            <Input icon={<ImPriceTag />} title={t("price-from")} state={startPrice} setState={setStartPrice} type={"number"} />
                            <Input title={t('auction-images')} state={images.join(',')} setState={(val: any) => setImages(val.split(','))} />
                            <Input icon={<VscDebugStepOut />} title={t('minimumIncrement')} state={minimumIncrement} setState={setMinimumIncrement} type='number' />
                            <Input icon={<BsCalendarDate />} title={t('start-date')} state={startDate} setState={setStartDate} type='date' />
                            <Input icon={<BsCalendarDate />} title={t('end-date')} state={endDate} setState={setEndDate} type='date' />
                            <Input icon={<MdOutlineProductionQuantityLimits />} title={t('quantity')} state={quantity} setState={setQuantity} type='number' />
                            <Input icon={<AiOutlineDeploymentUnit />} title={t('unit-of-measure')} state={unitOfMeasure} setState={setUnitOfMeasure} />
                            <Input icon={<MdCategory />} title={t('auction-category')} state={category} setState={setCategory} />
                            <Input icon={<FaLocationDot />} title={t('auction-location')} state={location} setState={setLocation} />
                        </div>
                        <Input icon={<MdDescription />} title={t('auctionDescription')} state={description} setState={setDescription} />
                        <Input icon={<TbAlignBoxCenterMiddle />} title={t('auctionTerms')} state={terms} setState={setTerms} />
                        <div className='grid w-full pt-6'>
                            <input type="submit" value={t("save")} className=' cursor-pointer justify-self-end font-sm px-3 py-1 bg-primary text-white rounded-md ' />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddAuctions