import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdOutlineCheckCircle, MdOutlineCircleNotifications } from "react-icons/md";
import { RiAuctionLine } from "react-icons/ri";
import { IoAddCircleOutline } from "react-icons/io5";
export const navlinks_en = [
    { title: "Personal Details", link: "personal-details", icon: <IoMdInformationCircleOutline /> },
    { title: "Notification", link: "notifications", icon: <MdOutlineCircleNotifications /> },
    { title: "Successful Auctions", link: "successful-auctions", icon: <MdOutlineCheckCircle /> },
    { title: "My Auctions", link: "my-auctions", icon: <RiAuctionLine /> },
    { title: "Add Auctions", link: "add-auctions", icon: <IoAddCircleOutline /> },
]
export const navlinks = [
    { title: "البيانات الشخصية", link: "personal-details", icon: <IoMdInformationCircleOutline /> },
    { title: "الاشعارات", link: "notifications", icon: <MdOutlineCircleNotifications /> },
    { title: "مزادات ناجحة", link: "successful-auctions", icon: <MdOutlineCheckCircle /> },
    { title: "مزاداتي", link: "my-auctions", icon: <RiAuctionLine /> },
    { title: "إضافة مزادات", link: "add-auctions", icon: <IoAddCircleOutline /> }
];