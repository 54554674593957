import { Link } from "react-router-dom"
import { LinkProps } from "../../types"

const MainLink = ({ title, href }: LinkProps) => {
    return (
        <div>
            <Link to={`${href}`}
            className=' bg-secondry px-5 py-2 rounded-md  transition-all duration-300  hover:bg-primary'>
                {title}
            </Link>
        </div>
    )
}

export default MainLink
