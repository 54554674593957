import { useState } from 'react'
import { useTranslation } from 'react-i18next';

const EmailAds = () => {
    const {t}= useTranslation();
    const [email, setEmail] = useState('');
    return (
        <div className='main-prop sharpGradient overflow-hidden'>
            <div className='max-container padding-container' data-aos="zoom-in-up">
            <div className='padding-container  py-8 rounded-md bg-darkColor text-white flex-between gap-10 xs:flex-col md:flex-row'>
                <div className=' text-2xl'>
                    {t("subscribe-email")}
                </div>
                <form className=' flex-center bg-white rounded-sm overflow-hidden'>
                    <input
                        type='email'
                        title='email'
                        value={email}
                        placeholder={t("emailPlaceholder")}
                        required onChange={(e) => setEmail(e.target.value)}
                        className='text-black xs:w-full md:w-64 focus:outline-none  bg-inherit ps-4 py-3'
                    />
                    <input
                        type="submit"
                        value={t("subscribe-now")}
                        className='bg-secondry py-3 px-2 border-none' />
                </form>
            </div>
            </div>
            
        </div>
    )
}

export default EmailAds
