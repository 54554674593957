import React from 'react'
import { useTranslation } from 'react-i18next';

const Notifications = () => {
    const { t } = useTranslation();
    return (
        <div className='bg-gray-200 pb-10'>
            <div className='mx-6  '>
                <div className=' flex-center text-center flex-col'>
                    <div className=' xs:w-72 sm:w-96 xs:h-72 sm:h-96'>
                        <img className='w-full h-full' src='/asset/no1.png' alt='not-found' />
                    </div>
                    <p className='py-5'>{t("no-notification")}</p>
                </div>
            </div>
        </div>
    )
}

export default Notifications